<template>
  <Layout>
    <div class="qrCode">
      <div class="getCode" v-if="!info.isPay">
        <h1>₹ {{ amount }}</h1>
        <div v-if="!info.isGetCode">
          <van-loading type="spinner" size="200px" color="#ff9933" />
          <p>Generating QR Code...</p>
        </div>
        <div v-else>
          <canvas id="qrcodeImg"></canvas>
          <p>
            Scan the QR using any UPI app on your phone like BHIM, PhonePe,
            Google Pay etc.
          </p>
        </div>
      </div>
      <div class="bottom">
        <button v-if="retryCount > 200" @click="checkStatus">
          PAY COMPLETED
        </button>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/layout/index.vue";
import { useRoute, useRouter } from "vue-router";
import { ref, nextTick, onMounted, reactive, onBeforeUnmount } from "vue";
import { Toast } from "vant";
import qrcode from "qrcode";
import { getPaymentResult, upiIntentPayment } from "@/api/payment";

let route = useRoute();
let router = useRouter();
let amount = route.query.amount;
let info = reactive({
  id: "",
  codeImg: "",
  isGetCode: false,
  isPay: false,
});

//获取二维码
onMounted(() => {
  let data = {
    onetimeId: sessionStorage.getItem("onetimeId"),
  };

  upiIntentPayment(data)
    .then((response) => {
      if (response && 200 === response.code) {
        getPaymentResultData = {
          originalOrderId: response.data.originalOrderId,
        };
        info.isGetCode = true;
        nextTick(() => {
          let qrCanvas = document.getElementById("qrcodeImg");
          qrcode.toCanvas(qrCanvas, response.data.intentUrl, {
            errorCorrectionLevel: "H",
            height: 250,
            width: 250,
          });
          getQRResult();
        });
      } else {
        pushFailure();
      }
    })
    .catch((error) => {
      pushFailure();
    });
});

//支付结果查询
let retryCount = ref(0);
let getPaymentResultData = {};
let getResult;

const getQRResult = () => {
  getPaymentResult(getPaymentResultData)
    .then((response) => {
      if (
        response.data &&
        (response.data.status === "SUCCESS" ||
          response.data.status === "FAILURE")
      ) {
        //已支付或支付失败
        clearTimeout(getPaymentResult);
        router.push({
          path: "/paymentResult",
          query: {
            result: response.data.status,
            type: "QR",
            amount: amount,
            paymentId: response.data.paymentId,
            // postCallbackUrl: response.data.postCallbackUrl,
          },
        });
      } else {
        //未支付重试
        retryCount.value++;
        if (retryCount.value > 200) {
          clearTimeout(getResult);
        } else {
          if (getResult == null) {
            getResult = setInterval(() => {
              getQRResult();
            }, 3000);
          }
        }
      }
    })
    .catch(function(error) {});
};

onBeforeUnmount(() => {
  clearTimeout(getResult);
});

//跳转失败页面
const pushFailure = () => {
  router.push({
    path: "/paymentResult",
    query: {
      result: "FAILURE",
      type: "QR",
      amount: amount,
    },
  });
};

//手动检查是否成功
const checkStatus = () => {
  getPaymentResult(getPaymentResultData)
    .then((response) => {
      if (
        response.data &&
        (response.data.status === "SUCCESS" ||
          response.data.status === "FAILURE")
      ) {
        router.push({
          path: "/paymentResult",
          query: {
            result: response.data.status,
            type: "QR",
            amount: amount,
            paymentId: response.data.paymentId,
          },
        });
      } else {
        Toast("PENDING");
      }
    })
    .catch(function(error) {});
};

let pay = () => {
  router.push("/payment");
};
</script>

<style scoped lang="scss" type="text/scss">
.bottom {
  width: 92%;
  position: absolute;
  bottom: 5%;
  color: #999999;
  font-size: 1.2rem;
  height: 50px;
  line-height: 50px;

  button {
    float: right;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    color: #ffffff;
    background-color: #ff9933;
    border-radius: 48px;
  }
}

.qrCode {
  width: 100%;
  height: 100%;

  .getCode {
    text-align: center;
    color: #333333;

    img {
      width: 75%;
      margin: 5% 0;
    }

    p {
      color: #666666;
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }

  .waitPayment {
    width: 100%;
    height: 100%;
    text-align: center;

    img {
      width: 25%;
    }

    h3 {
      margin: 5% 0;
    }

    p {
      font-size: 1rem;
      color: #666666;
      overflow: hidden;

      span:first-child {
        float: left;
        margin-bottom: 10%;
      }

      span:last-child {
        float: right;
        color: #333333;
      }
    }

    hr {
      margin: 8% 0;
      border: 1px solid #f5f5f5;
    }
  }
}

.van-loading {
  margin: 10%;
}
</style>
